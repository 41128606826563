@use '~/src/lib/_root';
@use '~/src/overtime-lib/src/www/lib/mixins';

@font-face {
	font-style: normal;
	font-weight: normal;
	src:
		url('~/src/images/fonts/vtf.woff2') format('woff2'),
		url('~/src/images/fonts/vtf.woff') format('woff');
	font-family: 'VTF League';
	font-display: swap;
}

//Inline from https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,400;8..144,600&display=swap
/* cyrillic-ext */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-wmF8tqHbFvug.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-UmF8tqHbFvug.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-ImF8tqHbFvug.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-4mF8tqHbFvug.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-8mF8tqHbFvug.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-EmF8tqHbE.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-wmF8tqHbFvug.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-UmF8tqHbFvug.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-ImF8tqHbFvug.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-4mF8tqHbFvug.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-8mF8tqHbFvug.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNeepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGfttPZktqc2VdZ80KvCLZaPcSBZtOx2MifRuWR28sPJtUMbsFEK6cRrleUx9Xgbm3WLHa_F4Ep4Fm0PN19Ik5Dntczx0wZGzhPlL1YNMYKbv9_1IQXOw7AiUJVXRrV8cWW4O8LJCoXjCnwSRSaLshNP1d9-EmF8tqHbE.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
		U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
	--header-background-color: black;
	--header-foreground-color: var(--color-white);
}
:root {
	--app-height: 100vh;
	--app-width: 100vw;
}
* {
	position: relative;
	box-sizing: border-box;
}
html {
	display: flex;
	flex-direction: column;
}
html {
	margin: 0;
	padding: 0;
	max-width: 100%;
	min-height: 100%;
	font-size: 16px;
	line-height: 1.4;
}
body {
	margin: 0;
	background: var(--color-white);
	padding: 0;
	width: 100%;
	max-width: 100%;
	font-family: var(--font-main);
}
p,
li {
	line-height: 1.7;
}
input {
	font-family: var(--font-main);
}
body,
#root {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: [full-start] 1fr [full-end];
	flex: 1;
	/* background-color: ${styles.BRAND_PRIMARY}; */
}
.content {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: [full-start content-start] 1fr [content-end full-end];
	grid-column: full;
}
@media (min-width: 990px) {
	.content {
		grid-template-columns: [full-start] 1fr [content-start] var(--size-content-width) [content-end] 1fr [full-end];
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: 600;
	font-family: var(--font-highlight);
}
body.font-loaded {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: var(--font-highlight);
		letter-spacing: 0;
	}
}
h1 {
	font-size: var(--font-h1);
}
h2 {
	font-size: var(--font-h2);
}
h3 {
	font-size: var(--font-h3);
}
time {
	display: contents;
}
img {
	display: inline-block;
}
button {
	cursor: pointer;
	outline: none;
	box-shadow: none;
	border: 0;
	font-size: inherit;
}
[role='button']:hover {
	cursor: pointer;
}
b {
	font-weight: 600;
}

:root {
	--gap: min(16px, 5vw);
	--row-gap: 0.55rem;
}
@include mixins.desktop {
	:root {
		--gap: 16px;
	}
}

@media (max-width: root.$size-desktop-width) {
	.desktop-only {
		display: none !important;
	}
}

@media (min-width: root.$size-desktop-width) {
	.mobile-only {
		display: none !important;
	}
}

a {
	color: var(--color-brand-primary);
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}
a.inherit {
	color: inherit;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

.padded {
	padding: var(--size-gap);
}
