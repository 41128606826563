@use '~/src/overtime-lib/src/www/lib/mixins';

.Banner {
	margin: 0;
	background-position: center;
	background-size: cover;
	color: var(--color-white);
	text-align: center;

	@include mixins.desktop {
		margin: var(--size-gap) 0;
	}

	.Container {
		display: flex;
		flex-direction: column;
		align-items: stretch;

		@include mixins.desktop {
			flex-direction: row;
		}

		.ImageContainer,
		video,
		picture,
		img {
			display: block;
			width: 100%;
			object-fit: cover;
			object-position: center;

			@include mixins.desktop {
				border-radius: 16px 0 0 16px;
				max-width: 850px;
			}
		}
		img {
			aspect-ratio: 16/9;
			width: 100%;
			height: 100%;
		}

		.BannerContent {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			background-color: var(--color-black);
			padding: var(--size-gap-2x);

			@include mixins.desktop {
				align-items: flex-start;
				border-radius: 0 16px 16px 0;
				padding: 0 48px;
				text-align: left;
			}
		}

		.ButtonsContainer {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			gap: var(--size-gap);

			@include mixins.desktop {
				flex-direction: row;
				align-items: flex-end;
			}

			.PrimeButton,
			.YoutubeButton {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: var(--size-gap-half);
				border-radius: 4px;
				padding: var(--size-gap-half) var(--size-gap);
				color: var(--color-white);
				text-align: center;
				&:hover {
					text-decoration: none;
				}
				svg {
					width: 20px;
					height: 20px;
				}
			}
			.PrimeButton {
				border: 1px solid var(--color-amazon-blue);
			}
			.YoutubeButton {
				border: 1px solid var(--color-youtube-red);
			}
			a {
				margin-top: var(--size-gap-half);
				min-width: 160px;
				max-width: 240px;
			}
		}
		h1 {
			margin: 0;
			line-height: 1.3;
		}
		p {
			margin: var(--size-gap-half) 0 var(--size-gap);
			max-width: 540px;
			line-height: 1.5;
		}
	}
}
