@use '~/src/overtime-lib/src/www/lib/mixins';

.Footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--color-black);
	color: var(--color-white);
	@include mixins.desktop {
		padding: var(--size-gap-4x) 0 var(--size-gap-2x);
	}
	nav {
		display: grid;
		// columns: 2;
		grid-template-columns: 1fr 1fr;
		column-gap: var(--size-gap-2x);
		row-gap: var(--size-gap-quarter);
		// flex-direction: column;
		align-items: flex-start;

		font-size: var(--font-small);
		@include mixins.desktop {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			gap: var(--size-gap-2x);
		}
		> section {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			@include mixins.desktop {
				align-items: flex-start;
			}
		}
		.LogoContainer {
			display: flex;
			grid-column: 1 / span 2;
			justify-content: center;
			margin: var(--size-gap);
			margin-top: var(--size-gap-3x);
			@include mixins.desktop {
				margin-top: var(--size-gap);
				img {
					height: 120px;
				}
			}
		}

		section > div:first-child {
			margin-top: var(--size-gap);
			padding: 4px;
			font-weight: 600;
			font-size: inherit;
			font-family: var(--font-highlight);
			text-transform: uppercase;
		}
		a {
			padding: 8px;
			@include mixins.desktop {
				padding: 4px;
			}
		}
		.Social {
			display: flex;
			grid-column: 1 / span 2;
			flex-direction: row;
			justify-content: center;
			align-self: center;
			gap: var(--size-gap-half);
			margin-top: var(--size-gap);
			@include mixins.desktop {
				margin-top: 0;
			}
		}
	}

	.LegalContainer {
		display: flex;
		justify-content: space-between;
		margin: var(--size-gap-2x);
		font-size: 10px;
	}
}
